<template>
  <van-popup
    v-model:show="show"
    position="bottom"
    round
    closeable
    close-on-popstate
    safe-area-inset-bottom
  >
    <div class="title">{{ title }}</div>
    <div class="content" :style="{ padding }">
      <slot></slot>
    </div>
  </van-popup>
</template>

<script>
import { Popup } from 'vant';
import { ref } from 'vue';

export default {
  name: 'BasePopup',
  props: {
    title: {
      type: String,
      default: '',
    },
    padding: {
      type: [String, Number],
    },
  },
  components: {
    [Popup.name]: Popup,
  },
  setup() {
    const show = ref(false);
    const openPopup = function () {
      show.value = true;
    };
    const closePopup = function () {
      show.value = false;
    };

    return {
      show,
      openPopup,
      closePopup,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: $font18;
  font-weight: 600;
  color: #525659;
  line-height: 18px;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 20px;
}
.content {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
}
</style>
