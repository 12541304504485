<template>
  <div class="container">
    <div class="setting-status">
      <div class="setting-status-box">
        <div v-if="!nowSetting" class="status">
          当前问诊状态：<span v-if="state.isStop" style="color: #ff5e5e">停诊</span>
          <span v-if="!state.isStop">{{ state.isInvisible ? '隐身接诊中' : '接诊中' }}</span>
        </div>
        <div v-else @click="handleBack">
          <van-icon name="arrow-left" />
          返回{{ prev.title }}
        </div>
        <!-- <div class="tips" @click="helpPopup.openPopup()">
          <div>?</div>
          设置帮助
        </div> -->
      </div>
    </div>
    <!-- <base-popup title="如何填写" ref="helpPopup">
      <div class="form-help">
        <div>
          1.仅供医生的线下面诊患者使用，需要拍照上传门诊或住院信息，医生不确认填写无效。
        </div>
      </div>
    </base-popup> -->
    <div class="setting-form padding15" v-if="nowSetting">
      <div class="basic">
        <router-view></router-view>
      </div>
    </div>

    <div class="setting-form padding15" v-show="!nowSetting">
      <div class="basic">
        <base-box :padding="0">
          <van-cell center title="消息免打扰" value="" label="开启后,您在设置的时间段内将不会收到消息提醒">
            <template #right-icon>
              <van-switch
                v-model="state.isCloseMessage"
                size="24"
                :active-value="1"
                :inactive-value="0"
                @change="handleCloseSwitch"
              />
            </template>
          </van-cell>
          <van-cell center title="开始时间" value="" v-if="state.isCloseMessage">
            <template #right-icon>
              <div class="label gray" @click="startTimeRef.openPopup()">
                <span>{{ state.closeMessageStartTime }} </span>
                <van-icon name="arrow" size="10" color="#BCC5CB" />
              </div>
            </template>
          </van-cell>

          <van-cell center title="结束时间" value="" v-if="state.isCloseMessage">
            <template #right-icon>
              <div class="label gray" @click="endTimeRef.openPopup()">
                <span>{{ state.closeMessageEndTime }} </span>
                <van-icon name="arrow" size="10" color="#BCC5CB" />
              </div>
            </template>
          </van-cell>
          <base-popup title="开始时间" ref="startTimeRef">
            <van-datetime-picker
              v-model="state.closeMessageStartTime"
              type="time"
              title=""
              @change="handleStartTimeChange"
              :show-toolbar="false"
              :min-hour="0"
              :max-hour="23"
            />
          </base-popup>
          <base-popup title="结束时间" ref="endTimeRef">
            <van-datetime-picker
              v-model="state.closeMessageEndTime"
              type="time"
              title=""
              @change="handleEndTimeChange"
              :show-toolbar="false"
              :min-hour="0"
              :max-hour="23"
            />
          </base-popup>
        </base-box>
      </div>
      <div class="sevice">
        <div class="lable-title">基本设置</div>

        <base-box :padding="0">
          <van-cell center title="开放问诊" value="关闭后可一键停诊全部咨询">
            <template #right-icon>
              <van-switch
                v-model="state.isStop"
                size="24"
                :active-value="0"
                :inactive-value="1"
                @click="handleSwitch('all')"
              />
            </template>
          </van-cell>
          <!-- <van-cell center title="开启隐身" value="开启后仅扫码患者可查到您">
            <template #right-icon>
              <van-switch
                v-model="state.isInvisible"
                :active-value="1"
                :inactive-value="0"
                @click="handleSwitch('ys')"
                size="24"
              />
            </template>
          </van-cell> -->

          <van-cell center title="快速咨询" value="开启后可参与抢单">
            <template #right-icon>
              <van-switch
                v-model="state.isFast"
                size="24"
                :active-value="1"
                :inactive-value="0"
                @click="handleSwitch('fast')"
              />
            </template>
          </van-cell>
        </base-box>
      </div>

      <div class="sevice">
        <div class="lable-title">图文问诊</div>
        <base-box :padding="0">
          <van-cell center title="咨询定价">
            <template #value>
              <div class="sevice-item">
                <div>设置价格和限号</div>
                <div class="label" @click="handleToSetting('imageText')">
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell center title="图文问诊">
            <template #value>
              <div class="sevice-item">
                <div>所有人都可咨询</div>
                <van-switch
                  v-model="state.isImageText"
                  :active-value="1"
                  :inactive-value="0"
                  size="24"
                  @click="handleSwitch('tw')"
                />
                <!-- <div class="label gray" @click="handleToSetting('imageText')">
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div> -->
              </div>
            </template>
          </van-cell>
          <van-cell center title="义诊咨询">
            <template #value>
              <div class="sevice-item">
                <div>快速提升咨询量</div>
                <van-switch
                  v-model="state.isFreeClinic"
                  :active-value="1"
                  :inactive-value="0"
                  size="24"
                  @click="handleSwitch('yz')"
                />
                <!-- <div class="label gray" @click="handleToSetting('imageText')">
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div> -->
              </div>
            </template>
          </van-cell>
          <van-cell center title="诊后咨询">
            <template #value>
              <div class="sevice-item">
                <div>仅扫码患者可咨询</div>
                <van-switch
                  v-model="state.isAfter"
                  :active-value="1"
                  :inactive-value="0"
                  size="24"
                  @click="handleSwitch('zh')"
                />
                <!-- <div
                  class="label gray"
                  @click="handleToSetting('afterConsultation')"
                >
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div> -->
              </div>
            </template>
          </van-cell>

          <!-- <van-cell center title="电话问诊">
            <template #value>
              <div class="sevice-item">
                <div></div>
                <van-switch
                  v-model="state.isTel"
                  :active-value="1"
                  :inactive-value="0"
                  size="24"
                  @click="handleSwitch('phone')"
                />
                <div class="label gray" @click="handleToSetting('phone')">
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div>
              </div>
            </template>
          </van-cell> -->

          <van-cell center title="门诊加号">
            <template #value>
              <div class="sevice-item">
                <div></div>
                <van-switch
                  v-model="state.isPlus"
                  :active-value="1"
                  :inactive-value="0"
                  size="24"
                  @click="handleSwitch('plus')"
                />
                <div class="label gray" @click="handleToSetting('plus')">
                  <span>去设置</span>
                  <van-icon name="arrow" size="10" color="#BCC5CB" />
                </div>
              </div>
            </template>
          </van-cell>

          <!-- <van-cell center title="续方配药">
            <template #value>
              <div class="sevice-item">
                <div></div>
                <van-switch
                  v-model="state.isPrescription"
                  size="24"
                  :active-value="1"
                  :inactive-value="0"
                  @click="handleSwitch('xf')"
                />
                <div
                  class="label gray"
                  @click="handleToSetting('prescription')"
                >
                  <div class="label gray">
                    <span>去设置</span>
                    <van-icon name="arrow" size="10" color="#BCC5CB" />
                  </div>
                </div>
              </div>
            </template>
          </van-cell> -->
        </base-box>
      </div>

      <div class="tips">
        1、问诊最长可持续48小时。<br />
        2、患者共有6次文字提问机会。<br />
        3、患者问诊费用和打赏心意100%归医生。<br />
        4、超过24小时未回复，咨询将被撤回，费用退还患者。
      </div>
    </div>
  </div>
</template>

<script>
import BaseBox from '@/components/BaseBox.vue';
import BasePopup from '@/components/BasePopup.vue';
import { reactive, ref } from '@vue/reactivity';
import { Cell, DatetimePicker, Icon, Popup, Switch } from 'vant';
import { getDoctorSetDto, openOrClose, setCloseMessage } from '@/api/doctor';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { watch } from '@vue/runtime-core';

export default {
  name: 'setting',
  components: {
    BaseBox,
    BasePopup,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    const nowSetting = ref(false);
    const helpPopup = ref(null);
    const prev = reactive({
      path: '',
      title: '',
    });
    const state = ref({
      isStop: 0,
      isAfter: 0,
      isFreeClinic: 0,
      isInvisible: 0,
      isPlus: 0,
      isPrescription: 0,
      isTel: 0,
      prescriptionMoney: 0,
      isCloseMessage: 0,
      closeMessageEndTime: '',
      closeMessageStartTime: '',
    });
    (async function () {
      const { data } = await getDoctorSetDto({
        doctorId: store.getters['user/userInfo'].id,
      });
      state.value = data;
    })();
    const router = useRouter();
    const handleToSetting = function (path) {
      nowSetting.value = true;
      router.push({ path: `/setting/${path}` });
    };

    const route = useRoute();
    const handleBack = function () {
      router.go(-1);
      // if (route.matched.length > 1) {
      //   nowSetting.value = true;
      //   prev.path = route.matched[route.matched.length - 2].path;
      //   prev.title = route.matched[route.matched.length - 2].meta.title;
      // }
    };

    const handleSwitch = function (openCloseEnum) {
      openOrClose({ openCloseEnum }).then((res) => {
        console.log(res);
      });
    };

    const startTimeRef = ref();
    const endTimeRef = ref();
    const handleCloseSwitch = async function (bool, params = {}) {
      const { code } = await setCloseMessage({
        isClose: bool || state.value.isCloseMessage,
        ...params,
      });
      if (code == 200) {
        state.value.isCloseMessage = bool || state.value.isCloseMessage;
      }
    };

    const handleStartTimeChange = function (startTime) {
      handleCloseSwitch(null, { startTime });
    };
    const handleEndTimeChange = function (endTime) {
      handleCloseSwitch(null, { endTime });
    };

    // 监听路由
    watch(
      () => route.matched,
      (path) => {
        if (path.length == 1) {
          nowSetting.value = false;
        }
        if (path[path.length - 2]) {
          prev.title = path[1].meta.prevTitle ? path[1].meta.prevTitle : path[0].meta.title;
        }
      },
    );

    return {
      nowSetting,
      helpPopup,
      state,
      handleToSetting,
      handleBack,
      handleSwitch,
      handleCloseSwitch,
      prev,
      startTimeRef,
      endTimeRef,
      handleEndTimeChange,
      handleStartTimeChange,
    };
  },
};
</script>

<style lang="scss">
@import '../../style/mixins.scss';

.setting-form {
  .van-cell__title {
    flex: none;
    padding-right: 7px;
    font-size: $font15;
    font-weight: 600;
    color: $black;
  }
  .van-cell__value,
  .van-cell__label {
    font-size: $font12;
    color: #bcc5cb;
    text-align: left;
  }
  height: 100vh;
  background-color: white;
  overflow-y: scroll;
  .tips {
    font-size: $font12;
    margin-bottom: 10px;
    line-height: 18px;
    color: #666666;
    margin-top: 20px;
  }
  .lable-title {
    color: #a6aeb3;
    font-size: $font12;
    margin-bottom: 15px;
    margin-left: 8px;
    span {
      color: $black;
    }
  }
  .basic {
    margin-top: 60px;
  }
  .sevice {
    margin-top: 22px;
  }
  .sevice-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      font-size: $font15;
      color: #5e7dff;
      span {
        padding-right: 10px;
      }
    }
    .gray {
      color: #bcc5cb;
    }
    .black {
      color: $black;
      font-weight: 600;
    }
  }
}
.oepntime-list {
  display: flex;
  flex-wrap: wrap;
  .time-item {
    text-align: center;
    color: #525659;
    font-size: $font12;
    line-height: 30px;
    height: 30px;
    width: 100px;
    background-color: #f0f4f7;
    border-radius: 15px;
    position: relative;
    margin-right: 2px;
    margin-bottom: 4px;
    .close {
      position: absolute;
      right: 0;
      top: -5px;
      width: 15px;
      height: 15px;
      @include bg-image('../../assets/del');

      border-radius: 100%;
      background-color: #ff5e5e;
    }
  }
  .time-item:nth-of-type(n + 3) {
    margin-right: 0;
  }
}
</style>
<style lang="scss" scoped>
.setting-status {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  font-size: $font15;
  color: #525659;
  z-index: 1;
  .setting-status-box {
    line-height: 40px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    .status {
      font-weight: 600;
    }
    span {
      color: #5e7dff;
    }
  }

  .tips {
    color: #bcc5cb;
    font-size: $font12;
    display: flex;
    align-items: center;
    div {
      display: flex;
      justify-content: center;
      margin-right: 5px;
      align-items: center;
      color: white;
      line-height: 18px;

      background-color: #bcc5cb;
      width: 14px;
      height: 14px;
      border-radius: 14px;
    }
  }
}
</style>
