import request from '@/utils/request';


export function getDoctorSearchList(data) {
  return request({
    url: '/doctor/getDoctorSearchList',
    method: 'post',
    data,
  });
}

/**
 * @description  医生详情
 * @param {memberId} params
 * @returns
 */
export function getDoctorDetail(params) {
  return request({
    url: '/doctor/getDoctorDetails',
    method: 'get',
    params,
  });
}
export function getDoctorCommentList(params) {
  return request({
    url: '/doctor/getDoctorCommentList',
    method: 'get',
    params,
  });
}


/**
 * @description  电话问诊可预约时间段
 * @param {doctorId} params
 * @returns
 */
export function getDoctorPhoneTimes(params) {
  return request({
    url: '/doctor/getDoctorPhoneTimes',
    method: 'get',
    params,
  });
}


/**
 * @description  关注医生/取消关注
 * @param {doctorId} params
 * @returns
 */
export function cancelFollowDoctor(data) {
  return request({
    url: '/doctor/cancelFollow',
    method: 'post',
    data,
  });
}
export function followDoctor(data) {
  return request({
    url: '/doctor/followDoctor',
    method: 'post',
    data,
  });
}

/**
 * @description  获取医生问诊开关
 * @param {openCloseEnum：all,yz义诊,zh诊后,plus加号预约,xf处方,phone电话问诊} params
 * @returns
 */
export function openOrClose(data) {
  return request({
    url: '/doctor/openOrClose',
    method: 'post',
    data,
  });
}

/**
 * @description  设置普通问诊金额
 * @param {moneyEnum，openCloseEnum：yz义诊,zh诊后,tw普通图文，money} params
 * @returns
 */
export function setOrdinary(data) {
  return request({
    url: '/doctor/setOrdinary',
    method: 'post',
    data,
  });
}
/**
 * @description  医生免打扰设置
 * @param {isClose，startTime，endTime} params
 * @returns
 */
export function setCloseMessage(data) {
  return request({
    url: '/newServices/setCloseMessage',
    method: 'post',
    data,
  });
}


/**
 * @description  获取医生问诊设置信息
 * @param {doctorId} params
 * @returns
 */
export function getDoctorSetDto(params) {
  return request({
    url: '/doctor/getDoctorSetDto',
    method: 'get',
    params,
  });
}


/**
 * @description  医生自己获取预约电话问诊时间段
 * @returns
 */
export function getMyPhoneTimes(params) {
  return request({
    url: '/doctor/getMyPhoneTimes',
    method: 'get',
    params,
  });
}
/**
 * @description  医生自己设置预约电话问诊时间段
 * @param {week,weekStr,value:比如用户选择09:00-20:00 这个时候前端需要分组处理 分隔成 09:00-10:00 …19:00-20:00}
 * @returns
 */
export function setPhoneDataValue(data) {
  return request({
    url: '/doctor/setPhoneDataValue',
    method: 'post',
    data,
  });
}

/**
 * @description  医生自己获取电话问诊临时停诊问诊时间段
 * @returns
 */
export function getLastPhoneStopModel(params) {
  return request({
    url: '/doctor/getLastPhoneStopModel',
    method: 'get',
    params,
  });
}
/**
 * @description  医生自己获取电话问诊临时停诊问诊列表
 * @returns
 */
export function getStopPhoneList(params) {
  return request({
    url: '/doctor/getStopPhoneList',
    method: 'get',
    params,
  });
}
/**
 * @description  医生自己设置预约电话问诊停诊时间段
 * @param {week,weekStr,value:比如用户选择09:00-20:00 这个时候前端需要分组处理 分隔成 09:00-10:00 …19:00-20:00}
 * @returns
 */
export function setStopPhoneDataValue(data) {
  return request({
    url: '/doctor/setStopPhoneDataValue',
    method: 'post',
    data,
  });
}


/**
 * @description  获取医生加号出诊设置信息
 * @returns
 */
export function getDoctorPlusInfo(params) {
  return request({
    url: '/doctor/getDoctorPlusInfo',
    method: 'get',
    params,
  });
}

/**
 * @description  设置医生加号出诊时间地址等
 * @returns
 */
export function setPlusInfo(data) {
  return request({
    url: '/doctor/setPlus',
    method: 'post',
    data,
  });
}

/**
 * @description  获取医生加号临时停诊单个
 * @returns
 */

export function getLastPlusStopModel(params) {
  return request({
    url: '/doctor/getLastPlusStopModel',
    method: 'get',
    params,
  });
}
/**
 * @description  获取医生加号临时停诊列表
 * @returns
 */
export function getStopPlusList(params) {
  return request({
    url: '/doctor/getStopPlusList',
    method: 'get',
    params,
  });
}

/**
 * @description  加号预约临时停诊添加/修改
 * @returns
 */
export function addStopPlus(data) {
  return request({
    url: '/doctor/addStopPlus',
    method: 'post',
    data,
  });
}
